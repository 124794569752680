<template>
     <div class="text-xs">
        <div class="fixed inset-0 z-50 opacity-50 bg-black" style="z-index:2110001 !important" @click="closeModal()"></div>
        <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50" style="z-index:2110002 !important" >
            <div class="relative mx-auto w-auto max-w-full" style="z-index:2110002 !important">
                <div class="bg-white w-full rounded-xl p-4 overflow-auto max-h-screen" >
                    <div :style="`width:${lebar}rem`">
                        <div class="flex justify-center text-left w-full">
                            <div class="text-left w-full">
                                <div class="w-full text-xs">
                                    <slot>
                                    </slot>
                                </div>

                            </div>
                        </div>
        
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props:['lebar'],

  methods: {
    testClick(){
        console.log("Test")
    },
     closeModal() {
        this.$emit('closeModal')
    },
  },
    computed:{
      
    }

};
</script>

<style>

</style>
